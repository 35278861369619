import _get from 'lodash/get'
import { vendorContact } from '@/services/Vendors/VendorContacts/store'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const firstName = _get(entry, 'firstName', '')
      const lastName = _get(entry, 'lastName', '')
      const emailAddress = _get(entry, 'emailAddress', '')
      const phoneNumber = _get(entry, 'phoneNumber', '')
      const workPhone = _get(entry, 'workPhone', '')
      const cellPhone = _get(entry, 'cellPhone', '')

      return {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        workPhone: workPhone,
        cellPhone: cellPhone
      }
    })

    this.rows = rows
  },

  async loadVendorContacts() {
    if (this.isDebug == true) console.debug('in loadVendorContact()...')
    await vendorContact
      .dispatch('getVendorContactList', {
        vendorID: this.vendorID
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    this.rows = []
    this.loadVendorContacts()

    this.loading = false
  }
}
